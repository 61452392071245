<template>
  <div class="ces-main" style="height: 100%">
    <div class="boxbutton">
      <el-row>
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <!--            <el-button type="primary" plain size="mini" v-if="tenantManagementSearchFlag" @click="tenantManagementSearch">查询</el-button>-->
            <el-button type="primary" plain size="mini" v-if="tenantManagementAddFlag" @click="tenantManagementAdd">新增</el-button>
            <el-button type="primary" plain size="mini" v-if="tenantManagementModifyFlag" @click="tenantManagementModify">修改</el-button>
            <el-button type="primary" plain size="mini" v-if="tenantManagementDelFlag" @click="tenantManagementDel">删除</el-button>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-input
              maxlength="50"
              placeholder="请输入收货方名称（模糊匹配）"
              v-model="AccurateAndUnique_tenantName"
              size="mini"
              style="width: 250px"
              class="input-with-input"
            >
              <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
            </el-input>
            <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
          </div>
        </el-col>
        <el-drawer title="高级查询条件" :before-close="cancelForm" :visible.sync="dialog" direction="rtl" custom-class="demo-drawer" ref="drawer">
          <div class="demo-drawer__content">
            <el-row>
              <el-col>
                <div class="grid-content bg-purple">
                  <el-card class="box-card">
                    <div>
                      <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                        <el-form-item label="收货方编码" prop="consumerId">
                          <el-input maxlength="18" v-model="dataForms.consumerId"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="库房编码" prop="houseId">
                          <el-input  maxlength="18" v-model="dataForms.houseId"></el-input>
                        </el-form-item> -->
                        <el-form-item label="收货方名称" prop="consumerName">
                          <el-input v-model="dataForms.consumerName" placeholder="支持模糊匹配"></el-input>
                        </el-form-item>
                        <el-form-item label="收货方地址" prop="consumerAddress">
                          <el-input v-model="dataForms.consumerAddress" placeholder="支持模糊匹配"></el-input>
                        </el-form-item>
                        <el-form-item label="收货方联系人" prop="consumerLinkman">
                          <el-input v-model="dataForms.consumerLinkman" placeholder="支持模糊匹配"></el-input>
                        </el-form-item>
                        <el-form-item label="收货方手机号" prop="consumerMobile">
                          <el-input v-model="dataForms.consumerMobile"></el-input>
                        </el-form-item>
                        <el-form-item label="线路名称" prop="lineName">
                          <el-input v-model="dataForms.lineName"></el-input>
                        </el-form-item>
                        <el-form-item label="线路类型" prop="lineType">
                          <el-select v-model="dataForms.lineType" placeholder="请选择" style="width: 100%">
                            <el-option label="市内" :value="0"></el-option>
                            <el-option label="外阜" :value="1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                      <div slot="footer" class="dialog-footer" style="text-align: center">
                        <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                        <el-button size="mini" v-if="tenantManagementSearchFlag" type="primary" @click="handleClose" :loading="loading">{{
                          loading ? '提交中 ...' : '查 询'
                        }}</el-button>
                        <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-drawer>
      </el-row>
    </div>
    <div class="tableMain">
      <ces-table
        size="mini"
        :isSelection="true"
        :isIndex="true"
        :isPagination="true"
        :isHandle="true"
        v-loading="loading"
        :tableData="tableData"
        :tableCols="tableCols"
        :pagination="pagination"
        @refresh="handleCurrentChange"
        ref="cesTable"
      >
      </ces-table>
      <!-- @handleClosButtonClick="handleClosButtonClick"-->
    </div>
    <div>
      <el-dialog
        title="分配菜单"
        :visible.sync="dialogVisibleMenu"
        :close-on-click-modal="false"
        :before-close="tree_handleClose"
        v-if="addDialogVisible"
        width="30%"
      >
        <el-tree
          :data="menuTreeList"
          show-checkbox
          check-strictly
          node-key="menuId"
          ref="tree"
          @check-change="checkChange"
          highlight-current
          :default-expanded-keys="expandTreeNodesByDefault"
          :props="defaultProps"
        >
        </el-tree>
        <div style="margin-top: 20px" align="center">
          <el-button type="primary" @click="handleSaveMenu()">保存</el-button>
          <el-button @click="handleClearMenu()">清空</el-button>
        </div>
      </el-dialog>
    </div>
    <!--    库房-->
    <el-dialog
      :title="dialogtenantAPPovaltitile"
      :visible.sync="dialog_tenantAPPoval"
      width="75%"
      :modal="true"
      center
      :before-close="handleClose_tenantAPPoval"
    >
      <el-card class="box-card" :body-style="{ padding: '10px' }">
        <div slot="header">
          <span style="font-size: 14px">收货方信息和线路信息</span>
        </div>
        <div>
          <el-form
            :model="tenantAPPovalForm"
            ref="tenantAPPovalForm"
            :rules="tenantAPPovalFormrules"
            label-width="110px"
            class="demo-ruleForm"
            size="mini"
            label-position="center"
          >
            <el-row>
              <el-col :span="6">
                <el-form-item label="库房编码" prop="houseId">
                  <el-input
                    :disabled="dialogtenantAPPovaltitile === '修改收货方信息和线路信息'"
                    v-model="tenantAPPovalForm.houseId"
                    @focus="companyFocus"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="收货方编码" prop="consumerId" v-if="isFlag">
                  <el-input :disabled="dialogtenantAPPovaltitile === '修改收货方信息和线路信息'" v-model="tenantAPPovalForm.consumerId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="收货方名称" prop="consumerName">
                  <el-input v-model="tenantAPPovalForm.consumerName"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="收货方联系人" prop="consumerLinkman">
                  <el-input v-model="tenantAPPovalForm.consumerLinkman"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="收货方手机号" prop="consumerMobile">
                  <el-input v-model="tenantAPPovalForm.consumerMobile"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="收货方固话" prop="consumerTelephone">
                  <el-input v-model="tenantAPPovalForm.consumerTelephone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <ProvinceRequired @SellerList="getSellerListCityLinkageCity" :SellerList="SellerList" :prop="'address'"></ProvinceRequired>
                <!-- <CountriesLink @addlist="getCity" :addList="tenantAPPovalForm" :prop="'address'"></CountriesLink> -->
              </el-col>
              <el-col :span="10">
                <el-form-item label="收货方地址" prop="consumerAddress">
                  <el-input v-model="tenantAPPovalForm.consumerAddress"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="线路名称" prop="lineName">
                  <el-input v-model="tenantAPPovalForm.lineName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="线路类型" prop="lineType">
                  <el-select v-model="tenantAPPovalForm.lineType" placeholder="请选择" style="width: 100%">
                    <el-option label="市内" :value="0"></el-option>
                    <el-option label="外阜" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="线路时效类型" prop="timelinessType">
                  <el-select v-model="tenantAPPovalForm.timelinessType" placeholder="请选择" style="width: 100%">
                    <el-option label="固定值" :value="1"></el-option>
                    <el-option label="小时" :value="2"></el-option>
                    <el-option label="某天某时刻" :value="3"></el-option>
                    <el-option label="某天某时某分" :value="4"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="线路时效" prop="timeliness">
                  <el-input v-model="tenantAPPovalForm.timeliness"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="备注" prop="remark">
                  <el-input v-model="tenantAPPovalForm.remark"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="save_tenantAPPoval">提 交</el-button>
        <el-button @click="handleClose_tenantAPPoval">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="库房查询" :visible.sync="dialogTableVisible">
      <el-row>
        <el-form :inline="true" v-model="form" size="mini" label-position="center">
          <el-col :span="9">
            <div class="grid-content">
              <el-form-item label="编码" label-width="60px" style="width: 220px">
                <el-input size="mini" v-model="form.houseId" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="grid-content">
              <el-form-item label="名称" label-width="60px" style="width: 220px">
                <el-input size="mini" v-model="form.houseName" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-button type="primary" plain size="mini" @click="companySearch">查询</el-button>
              <el-button type="primary" plain size="mini" @click="companyOkay">确定</el-button>
            </div>
          </el-col>
        </el-form>
      </el-row>
      <el-row class="waybill_add_dialog">
        <flight-table
          :tableData="tableDatadialog"
          :columns="columns"
          :pagesizes="pagesizes"
          :isPagination="true"
          @handleSelectionChange="handleSelectionChange"
          @clickTable="clickTable"
          @handleCurrentChange="handleCurrentChange_compan"
          @handleSizeChange="handleSizeChange"
          :total="tableDatadialog_pagination.total"
          :pageSize="tableDatadialog_pagination.pageSize"
          :current-page="tableDatadialog_pagination.pageNum"
        ></flight-table>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//引入组件
import cesTable from '../../components/common/table.vue'
import Drawer from '../../components/common/drawer.vue'
import axios from 'axios'
import arrayQuery from '../../components/arrayQuery.js'
import CityLinkageReg from '../../components/common/cityLinkageReg.vue'
import CountriesLink from '../../components/common/countriesLink.vue'
import ProvinceRequired from '../../components/common/ProvinceRequired.vue'
import qs from 'qs'
import store from '../.././store/index'
import $ from 'jquery'
import 'element-ui/lib/theme-chalk/index.css'
import flightTable from '../../components/common/flightTable'
export default {
  data() {
    function validateProvince(rules, value, callback) {
      console.log(value)
      if (!value) {
        callback(new Error('请选择省'))
      } else {
        callback()
      }
    }
    return {
      table: false,
      dialog: false,
      loading: false,
      isFlag: true,
      tableDatadialog: [],
      dialogtenantAPPovaltitile: '',
      dialog_tenantAPPoval: false,
      tenantEnumFlag: false,
      dialogTableVisible: false,
      form: {
        houseId: '',
        houseName: '',
      },
      AccurateAndUnique_tenantName: '',
      dataForms: {
        houseName: '',
        // houseId:'',
        // status:'',
        houseAddress: '',
        houseId: '',
        consumerId: '',
        consumerName: '',
        consumerAddress: '',
        consumerLinkman: '',
        consumerMobile: '',
        consumerTelephone: '',
        lineName: '',
        lineType: '',
        remark: '',
        tenantId: '',
        creator: '',
        createTime: '',
        modifier: '',
        modifyTime: '',
      },
      tenantAPPovalForm: {
        consumerId: '',
        lineName: '',
        lineType: '',
        timelinessType: '',
        timeliness: '',
        tenantId: '',
        creator: '',
        createTime: '',
        modifier: '',
        modifyTime: '',
        houseId: '',
        houseName: '',
        consumerName: '',
        consumerLinkman: '',
        consumerMobile: '',
        consumerTelephone: '',
        consumerAddress: '',
        remark: '',
        province: '',
        city: '',
        area: '',
        // status:'',
        houseAddress: '',
        provinceCode: '',
        cityCode: '',
        districtCode: '',
      },
      addList: {
        provinceCode: '',
        cityCode: '',
        districtCode: '',
        province: '',
        city: '',
        area: '',
      },
      userManagementForm: {
        houseId: '',
        houseName: '',
      },
      SellerList: {
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        area: '',
        areaName: '',
      },

      columns: [
        {
          id: 'selection',
          type: 'selection',
          label: '',
          fixed: 'left',
          width: '55',
          prop: '',
          isShow: true,
          align: 'center',
        },
        {
          id: 'houseId',
          type: '',
          label: '编码',
          fixed: false,
          width: '120',
          prop: 'houseId',
          isShow: true,
          align: 'center',
        },
        {
          id: 'houseName',
          type: '',
          label: '名称',
          fixed: false,
          width: '180',
          prop: 'houseName',
          isShow: true,
          align: 'center',
        },
      ],
      pagesizes: [5, 10, 15, 20],
      tenantAPPovalFormrules: {
        timeliness: [{ required: true, message: '请输入线路时效', trigger: 'blur' }],
        address: [{ required: true, validator: validateProvince, trigger: ['blur', 'change'] }],
        consumerId: [{ required: true, message: '请输入库房编码', trigger: ['blur', 'change'] }],
        houseId: [{ required: true, message: '请输入库房名称', trigger: ['blur', 'change'] }],
        consumerName: [{ required: true, message: '请输入收货方名称', trigger: 'blur' }, { max: 64 }],
        consumerLinkman: [{ required: true, message: '请输入收货方联系人', trigger: 'blur' }, { max: 64 }],
        consumerMobile: [
          { required: true, message: '请输入收货方手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: '^1[345789]\\d{9}$',
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        // consumerTelephone: [
        //   { required: true,message: '请输入收货方固话', trigger: 'blur' },
        //   {  max: 16,},
        // ],
        consumerAddress: [{ required: true, message: '请输入收货方地址', trigger: 'blur' }],
        lineName: [{ required: true, message: '请输入线路名称', trigger: 'blur' }],
        lineType: [{ required: true, message: '请输入线路类型', trigger: 'blur' }],
        timelinessType: [{ required: true, message: '请输入线路时效类型', trigger: 'blur' }],

        remark: [
          {
            max: 128,
            // pattern: '[\u4e00-\u9fa5]',
            // message: "请输入正确的备注",
          },
        ],
        // status: [
        //   { required: true,message: '请选择是否启用', trigger: 'blur' }
        // ],
      },
      formLabelWidth: '80px',
      timer: null,
      apiId: 'null',
      fieldList: [],
      // 表格
      tableData: [], //表格数据
      tableCols: [
        //表格列数据
        { label: '收货方编码', prop: 'consumerId', width: '120', align: 'center' },
        { label: '库房编码', prop: 'houseId', width: '120', align: 'center' },
        { label: '收货方名称', prop: 'consumerName', width: '120', align: 'center' },
        { label: '省', prop: 'provinceCode', width: '100', align: 'center' },
        { label: '市', prop: 'cityCode', width: '100', align: 'center' },
        { label: '区', prop: 'districtCode', width: '100', align: 'center' },
        { label: '收货方地址', prop: 'consumerAddress', align: 'center', width: '350' },
        { label: '收货方联系人', prop: 'consumerLinkman', align: 'center', width: '120' },
        { label: '收货方手机号', prop: 'consumerMobile', width: '120', align: 'center' },
        { label: '收货方固话', prop: 'consumerTelephone', width: '120', align: 'center' },

        { label: '线路名称', prop: 'lineName', align: 'center', width: '120' },
        {
          label: '线路类型',
          prop: 'lineType',
          align: 'center',
          width: '120',
          formatter: row => {
            if (row.lineType == 1) {
              return '外阜'
            } else if (row.lineType == 0) {
              return '市内'
            } else {
              return ''
            }
          },
        },
        {
          label: '线路时效类型',
          prop: 'timelinessType',
          width: '120',
          align: 'center',
          formatter: row => {
            if (row.timelinessType == 1) {
              return '固定值'
            } else if (row.timelinessType == 2) {
              return '小时'
            } else if (row.timelinessType == 3) {
              return '某天某时刻'
            } else if (row.timelinessType == 4) {
              return '某天某时某分'
            }
          },
        },
        { label: '线路时效值', prop: 'timeliness', width: '120', align: 'center' },
        { label: '备注', prop: 'remark', align: 'center', width: '320' },
        { label: '创建人', prop: 'creator', align: 'center', width: '120' },
        {
          label: '创建时间',
          prop: 'createTime',
          align: 'center',
          width: '160',
          formatter: (row, column) => {
            if (row.createTime == null) {
              return ''
            } else {
              return this.$moment(row.createTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        { label: '更新人', prop: 'modifier', align: 'center', width: '120' },
        {
          label: '更新时间',
          prop: 'modifyTime',
          align: 'center',
          width: '160',
          formatter: (row, column) => {
            if (row.modifyTime == null) {
              return ''
            } else {
              return this.$moment(row.modifyTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        // {label:'库房地址',prop:'houseAddress',width:'200',align:'center'},
        // {label:'省',prop:'provinceCode',width:'200',align:'center',
        //   formatter: async (row) => {
        //     if(row.provinceCode) {
        //       let provinceData  = await this.trans(row.provinceCode)
        //       // console.log(provinceData )
        //       return provinceData
        //     }
        //   }
        // },
        // {label:'市',prop:'cityCode',width:'200',align:'center'},
        // {label:'区',prop:'districtCode',width:'200',align:'center'},
        // {label:'启用状态',prop:'status',align:'center',
        //   formatter: (row) => {
        //     if (row.status == 1) {
        //       return "启用";
        //     } else if (row.status == 0) {
        //       return "冻结";
        //     } else {
        //       return "";
        //     }
        //      }
        // },
        // {label:'创建人',prop:'creator',align:'center',width:'120'},
        // {label:'修改人',prop:'modifier',align:'center'},
        // {label:'创建时间',prop:'createTime',align:'center',width:'160',
        //   formatter: (row, column) => {
        //     if(row.createTime == null){
        //       return ''
        //     }else {
        //       return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
        //     }
        //   }
        // },
        // {label:'修改时间',prop:'modifyTime',align:'center',width:'160',
        //   formatter: (row, column) => {
        //     if(row.modifyTime == null){
        //       return ''
        //     }else {
        //       return this.$moment(row.modifyTime).format("YYYY-MM-DD HH:mm:ss")
        //     }
        //   }
        // },
      ],
      pagination: {
        //分页数据
        pageSize: 20,
        pageNum: 1,
        total: 0,
      },
      tableDatadialog_pagination: {
        pageSize: 5,
        pageNum: 1,
        total: 0,
      },
      merge: [],
      dialogVisibleMenu: false,
      menuTreeList: [],
      defaultProps: {
        children: 'children',
        label: 'menuName',
      },
      houseId: null,
      tenantManagementSearchFlag: true,
      tenantManagementAddFlag: true,
      tenantManagementModifyFlag: true,
      tenantManagementDelFlag: true,
      expandTreeNodesByDefault: [],
      addDialogVisible: false,
    }
  },
  watch: {
    'tenantAPPovalForm.timelinessType'(val) {
      console.log(typeof val)
      // 根据时效表单项的值来动态设置内容表单项的校验规则
      switch (val) {
        case 1:
          this.tenantAPPovalFormrules.timeliness = [
            {
              required: true,
              pattern: '当日达|次日达|隔日达',
              message: '请输入当日达,次日达,隔日达',
              trigger: 'blur',
            },
          ]
          break
        case 2:
          this.tenantAPPovalFormrules.timeliness = [
            {
              required: true,
              pattern: '^(?:[1-9]?\\d|360)H$',
              message: '校验失败，格式为 小时数+H，例 24H，表示24小时内送达，最多支持360个小时数值',
              trigger: 'blur',
            },
          ]
          break
        case 3:
          this.tenantAPPovalFormrules.timeliness = [
            {
              required: true,
              pattern: '^[0-9]D([0][0-9]|[1][0-9]|[0-2][0-3])$',
              message: '校验失败，格式为 0D00，例 1D10，表示第二天10点送达,范围 0D00-9D00，最多支持9天',
              trigger: 'blur',
            },
          ]
          break
        case 4:
          this.tenantAPPovalFormrules.timeliness = [
            {
              required: true,
              pattern: '^[0-9]D([0-1][0-9]|2[0-3])([0-5][0-9]$|60)$',
              message: '校验失败，格式为 0D0000，例 1D1020，表示第二天10点20分送达,范围 0D0000-9D2359，最多支持9天',
              trigger: 'blur',
            },
          ]
          break
        default:
          break
      }
    },
  },
  components: {
    //引入组件后注册组件
    cesTable,
    Drawer,
    flightTable,
    CityLinkageReg,
    CountriesLink,
    ProvinceRequired,
    //  SellerListCityLinkage,
  },

  mounted() {
    this.getTableAndForm()
    this.getMenuTree()
    let parentId = '2_3_10'
    let currentPageButtons = arrayQuery.text(parentId)
    let _this = this
    _this.currentPageButtons = currentPageButtons
    //查询按钮权限
    let tenantManagementSearch = currentPageButtons.find(item => {
      return item.menuId == '2_3_10_1'
    })
    if (tenantManagementSearch == undefined) {
      _this.tenantManagementSearchFlag = false
    } else {
      _this.tenantManagementSearchFlag = true
    }
    //新增按钮权限
    let tenantManagementAdd = currentPageButtons.find(item => {
      return item.menuId == '2_3_10_2'
    })
    if (tenantManagementAdd == undefined) {
      _this.tenantManagementAddFlag = false
    } else {
      _this.tenantManagementAddFlag = true
    }
    //修改按钮权限
    let tenantManagementModify = currentPageButtons.find(item => {
      return item.menuId == '2_3_10_3'
    })
    if (tenantManagementModify == undefined) {
      _this.tenantManagementModifyFlag = false
    } else {
      _this.tenantManagementModifyFlag = true
    }
    //删除按钮权限
    let tenantManagementDel = currentPageButtons.find(item => {
      return item.menuId == '2_3_10_4'
    })
    if (tenantManagementDel == undefined) {
      _this.tenantManagementDelFlag = false
    } else {
      _this.tenantManagementDelFlag = true
    }
  },
  methods: {
    //库房
    companyFocus() {
      this.companySearch()
      this.dialogTableVisible = true
    },
    handleSizeChange(val) {
      this.tableDatadialog_pagination.pageSize = val
      this.companySearch()
    },
    tree_handleClose() {
      this.expandTreeNodesByDefault.length = 0
      this.dialogVisibleMenu = false
      this.addDialogVisible = false
    },
    // trans(val){
    //   return new Promise((resolve,reject)=>{
    //     axios.get(`${this.$apiPath}/api/pm/region/getRegionName?adcode=${val}&level=1`).then(res=>{
    //       // console.log(res)
    //       resolve(res.data)
    //     }).catch(err=>console.error(err))
    //   })
    // },
    trans(tableData) {
      tableData.forEach(e => {
        if (e.provinceCode) {
          axios
            .get(`${this.$apiPath}/api/pm/region/getRegionName?adcode=${e.provinceCode}&level=1`)
            .then(res => {
              e.provinceCode = res.data
            })
            .catch(err => console.error(err))
        }
      })
      tableData.forEach(e => {
        if (e.cityCode) {
          axios
            .get(`${this.$apiPath}/api/pm/region/getRegionName?adcode=${e.cityCode}&level=2`)
            .then(res => {
              e.cityCode = res.data
            })
            .catch(err => console.error(err))
        }
      })
      tableData.forEach(e => {
        if (e.districtCode) {
          axios
            .get(`${this.$apiPath}/api/pm/region/getRegionName?adcode=${e.districtCode}&level=3`)
            .then(res => {
              e.districtCode = res.data
            })
            .catch(err => console.error(err))
        }
      })
    },

    getCity(data) {
      this.tenantAPPovalForm.province = data.province
      this.tenantAPPovalForm.city = data.city
      this.tenantAPPovalForm.area = data.area
    },
    getSellerListCityLinkageCity(data) {
      this.tenantAPPovalForm.province = data.province
      this.$set(this.tenantAPPovalForm, 'address', data.province)
      this.tenantAPPovalForm.city = data.city
      this.tenantAPPovalForm.area = data.area
      this.$refs.tenantAPPovalForm.validateField('address', error => {})
      // this.SellerList.province = data.province
      // this.SellerList.city = data.city
      // this.SellerList.area = data.area
    },
    // checkbox的触发接收
    handleSelectionChange(val) {
      this.companyData = val.val
    },
    // 双击事件的行触发
    clickTable(val) {
      this.tenantAPPovalForm.houseId = val.row.houseId
      this.tenantAPPovalForm.houseName = val.row.houseName
      this.dialogTableVisible = false
    },
    handleCurrentChange_compan(val) {
      this.tableDatadialog_pagination.pageNum = val
      this.companySearch()
    },
    companyOkay() {
      if (this.companyData.length == 0) {
        this.$message({
          message: '请先勾选一条公司信息',
          type: 'warning',
        })
      } else if (this.companyData.length > 1) {
        this.$message({
          message: '新增用户仅允许勾选一条公司信息',
          type: 'warning',
        })
      } else {
        this.$set(this.tenantAPPovalForm, 'houseId', this.companyData[0].houseId)
        this.$set(this.tenantAPPovalForm, 'houseName', this.companyData[0].houseName)
        this.$refs.tenantAPPovalForm.clearValidate()
        this.dialogTableVisible = false
      }
    },
    companySearch() {
      let params = {
        houseId: this.form.houseId,
        houseName: this.form.houseName,
        pageSize: this.tableDatadialog_pagination.pageSize,
        pageNo: this.tableDatadialog_pagination.pageNum,
      }
      $.ajax({
        type: 'post',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization',
          Authorization: this.$store.state.token,
        },
        //设置允许ajax请求鞋带cookie即可
        xhrFields: {
          withCredentials: true,
        },
        async: true,
        url: `${this.$apiPath}/api/urban/warehouse/queryWarehouseCheckPage`,
        data: JSON.stringify(params),
        beforeSend: request => {
          request.setRequestHeader('Authorization', this.$store.state.token)
          // request.setRequestHeader("token", this.$store.state.token);
        },
        success: res => {
          if (res.success == true) {
            console.log(res)
            this.tableDatadialog = res.data.items
            this.tableDatadialog_pagination.total = res.data.total
          } else {
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        },
      })
      // const data = new URLSearchParams();
      // data.append('houseId', this.form.houseId);
      // data.append('houseName', this.form.houseName);
      // data.append('pageSize', this.tableDatadialog_pagination.pageSize);
      // data.append('pageNo', this.tableDatadialog_pagination.pageNum);
      // axios({
      //   method: 'post',
      //   url: `${this.$apiPath}/api/urban/warehouse/queryWarehouseCheckPage`,
      //   data:data
      // }).then(res=> {
      //   console.log(res)
      //   this.tableDatadialog = res.data.items;
      //   this.tableDatadialog_pagination.total = res.data.total;
      // });
    },
    checkChange(data, check) {
      // 父节点操作
      if (data.parentId !== null) {
        if (check === true) {
          // 如果选中，设置父节点为选中
          this.$refs.tree.setChecked(data.parentId, true)
        } else {
          // 如果取消选中，检查父节点是否该取消选中（可能仍有子节点为选中状态）
          var parentNode = this.$refs.tree.getNode(data.parentId)
          var parentHasCheckedChild = false
          for (var i = 0, parentChildLen = parentNode.childNodes.length; i < parentChildLen; i++) {
            if (parentNode.childNodes[i].checked === true) {
              parentHasCheckedChild = true
              break
            }
          }
          if (!parentHasCheckedChild) this.$refs.tree.setChecked(data.parentId, false)
        }
      }
      // 子节点操作，如果取消选中，取消子节点选中
      if (data.children != null && check === false) {
        for (var j = 0, len = data.children.length; j < len; j++) {
          var childNode = this.$refs.tree.getNode(data.children[j].menuId)
          if (childNode.checked === true) {
            this.$refs.tree.setChecked(childNode.data.menuId, false)
          }
        }
      }
    },
    save_tenantAPPoval() {
      this.$refs['tenantAPPovalForm'].validate(valid => {
        if (valid) {
        } else {
          this.$message.error('请检查您输入的数据,请核对后重试!')
        }
      })
      // const data = new URLSearchParams();
      let params = {
        houseId: this.tenantAPPovalForm.houseId,
        houseName: this.tenantAPPovalForm.houseName,
        consumerId: this.tenantAPPovalForm.consumerId,
        consumerName: this.tenantAPPovalForm.consumerName,
        consumerLinkman: this.tenantAPPovalForm.consumerLinkman,
        consumerMobile: this.tenantAPPovalForm.consumerMobile,
        houseAddress: this.tenantAPPovalForm.houseAddress,
        consumerTelephone: this.tenantAPPovalForm.consumerTelephone,
        consumerAddress: this.tenantAPPovalForm.consumerAddress,
        remark: this.tenantAPPovalForm.remark,
        lineName: this.tenantAPPovalForm.lineName,
        lineType: this.tenantAPPovalForm.lineType,
        timelinessType: this.tenantAPPovalForm.timelinessType,
        timeliness: this.tenantAPPovalForm.timeliness,
        tenantId: this.tenantAPPovalForm.tenantId,
        creator: this.tenantAPPovalForm.creator,
        createTime: this.tenantAPPovalForm.createTime,
        modifier: this.tenantAPPovalForm.modifier,
        modifyTime: this.tenantAPPovalForm.modifyTime,
        provinceCode: this.tenantAPPovalForm.province,
        cityCode: this.tenantAPPovalForm.city,
        districtCode: this.tenantAPPovalForm.area,
      }

      if (this.dialogtenantAPPovaltitile == '新增收货方信息和线路信息') {
        //运单新增
        $.ajax({
          type: 'post',
          dataType: 'json',
          contentType: 'application/json',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Authorization',
            Authorization: this.$store.state.token,
          },
          //设置允许ajax请求鞋带cookie即可
          xhrFields: {
            withCredentials: true,
          },
          async: true,
          url: `${this.$apiPath}/api/urban/consumer/addConsumerInfo`,
          data: JSON.stringify(params),
          beforeSend: request => {
            request.setRequestHeader('Authorization', this.$store.state.token)
            // request.setRequestHeader("token", this.$store.state.token);
          },
          success: res => {
            console.log(res)
            if (res.success == true) {
              this.$message({
                message: '新增成功',
                type: 'success',
              })
              this.handleClose_tenantAPPoval()
              this.tenantManagementSearch()
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
              })
            }
          },
        })
      } else if (this.dialogtenantAPPovaltitile == '修改收货方信息和线路信息') {
        // data.append('houseId', this.merge[0].houseId);
        params.houseId = this.merge[0].houseId
        $.ajax({
          type: 'post',
          dataType: 'json',
          contentType: 'application/json',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Authorization',
            Authorization: this.$store.state.token,
          },
          //设置允许ajax请求鞋带cookie即可
          xhrFields: {
            withCredentials: true,
          },
          async: true,
          url: `${this.$apiPath}/api/urban/consumer/updateConsumerInfo`,
          data: JSON.stringify(params),
          beforeSend: request => {
            request.setRequestHeader('Authorization', this.$store.state.token)
            // request.setRequestHeader("token", this.$store.state.token);
          },
          success: res => {
            console.log(res)
            if (res.success == true) {
              this.$message({
                message: '修改成功',
                type: 'success',
              })
              this.handleClose_tenantAPPoval()
              this.tenantManagementSearch()
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
              })
            }
          },
        })
      }
    },
    handleClose_tenantAPPoval() {
      this.dialog_tenantAPPoval = false
      this.$refs.tenantAPPovalForm.resetFields()
      this.addList.provinceCode = ''
      this.addList.cityCode = ''
      this.addList.districtCode = ''
      this.tenantAPPovalForm.province = ''
      this.tenantAPPovalForm.city = ''
      this.tenantAPPovalForm.area = ''
      this.SellerList.province = ''
      this.SellerList.city = ''
      this.SellerList.area = ''
      this.tenantManagementSearch()
    },
    //此方法是点击分页时触发的查询，
    handleCurrentChange() {
      this.tenantManagementSearch()
    },
    //获取表格和表单数据
    async getTableAndForm() {
      this.tenantManagementSearch()
    },
    handleQuery() {
      //查询
      this.pagination.pageSize = 10
      this.pagination.pageNum = 1
      this.pagination.total = 0
      //快捷查询
      this.loading = true
      // const data = new URLSearchParams();
      let params = {
        consumerName: this.AccurateAndUnique_tenantName,
        // consumerId:'',
        // consumerAddress:'',
        // consumerLinkman:'',
        // consumerMobile:'',
        // lineName:'',
        // lineType:'',
        pageNo: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
      }
      // console.log(this.$store.state.token)
      $.ajax({
        type: 'post',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization',
          Authorization: this.$store.state.token,
        },
        //设置允许ajax请求鞋带cookie即可
        xhrFields: {
          withCredentials: true,
        },
        async: true,
        url: `${this.$apiPath}/api/urban/consumer/queryConsumerInfosPage`,
        data: JSON.stringify(params),
        beforeSend: request => {
          request.setRequestHeader('Authorization', this.$store.state.token)
          // request.setRequestHeader("token", this.$store.state.token);
        },
        success: res => {
          console.log(res)
          this.tableData = res.data.items
          this.trans(this.tableData)
          this.pagination.total = res.data.total
          this.loading = false
        },
      })
    },
    // 获取表格勾选数据
    selectionLineChangeHandle(val) {
      this.merge = this.$refs.cesTable.multipleSelection
    },
    //查询
    tenantManagementSearch() {
      this.loading = true
      let params = {
        consumerId: this.dataForms.consumerId,
        // consumerName:this.AccurateAndUnique_tenantName,
        consumerName: this.dataForms.consumerName,
        consumerAddress: this.dataForms.consumerAddress,
        consumerLinkman: this.dataForms.consumerLinkman,
        consumerMobile: this.dataForms.consumerMobile,
        lineName: this.dataForms.lineName,
        lineType: this.dataForms.lineType,
        pageNo: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
      }
      $.ajax({
        type: 'post',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization',
          Authorization: this.$store.state.token,
        },
        //设置允许ajax请求鞋带cookie即可
        xhrFields: {
          withCredentials: true,
        },
        async: true,
        url: `${this.$apiPath}/api/urban/consumer/queryConsumerInfosPage`,
        data: JSON.stringify(params),
        beforeSend: request => {
          request.setRequestHeader('Authorization', this.$store.state.token)
          //  request.setRequestHeader("token", store.state.token);
        },
        success: res => {
          console.log(res)
          this.tableData = res.data.items
          this.trans(this.tableData)
          this.pagination.total = res.data.total
          this.loading = false
        },
      })
    },
    tenantManagementSearch_Multiple() {
      this.pagination.pageSize = 10
      this.pagination.pageNum = 1
      this.pagination.total = 0
      this.tenantManagementSearch()
    },
    //新增
    tenantManagementAdd() {
      this.isFlag = false
      this.dialogtenantAPPovaltitile = '新增收货方信息和线路信息'
      this.dialog_tenantAPPoval = true
      this.tenantEnumFlag = false
    },
    //修改
    tenantManagementModify() {
      this.isFlag = true
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '修改操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '修改操作只能选择单条数据进行操作',
        })
      } else {
        this.dialogtenantAPPovaltitile = '修改收货方信息和线路信息'
        this.dialog_tenantAPPoval = true
        this.tenantEnumFlag = true
        axios.get(`${this.$apiPath}/api/urban/consumer/findConsumerInfo/` + this.merge[0].consumerId).then(res => {
          if (res.data.data.status == true) {
            res.data.data.status = '1'
          } else if (res.data.data.status == false) {
            res.data.data.status = '0'
          }
          if (res.data.data.chintBusiness == null) {
            res.data.data.chintBusiness = ''
          } else {
            res.data.data.chintBusiness = JSON.stringify(res.data.data.chintBusiness)
          }
          if (res.data.data.outGoing == null) {
            res.data.data.outGoing = ''
          } else {
            res.data.data.outGoing = JSON.stringify(res.data.data.outGoing)
          }
          if (res.data.data.regionLevel == null) {
            res.data.data.regionLevel = ''
          } else {
            res.data.data.regionLevel = JSON.stringify(res.data.data.regionLevel)
          }
          res.data.data.moduleId = JSON.stringify(res.data.data.moduleId)
          this.tenantAPPovalForm = res.data.data
          this.tenantAPPovalForm.province = res.data.data.provinceCode
          this.tenantAPPovalForm.city = res.data.data.cityCode
          this.tenantAPPovalForm.area = res.data.data.districtCode
          this.SellerList.province = res.data.data.provinceCode
          this.SellerList.city = res.data.data.cityCode
          this.SellerList.area = res.data.data.districtCode
        })
      }
    },
    //删除
    tenantManagementDel() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '删除操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '删除操作只能选择单条数据进行操作',
        })
      } else {
        this.$confirm('此操作将对库房进行删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            axios.get(`${this.$apiPath}/api/urban/consumer/deleteConsumerInfo/` + this.merge[0].consumerId).then(res => {
              if (res.data.success == true) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                })
                this.tenantManagementSearch()
              } else {
                this.$alert(res.data.msg, '提示', {
                  confirmButtonText: '确定',
                })
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作',
            })
          })
      }
    },
    handleClose() {
      if (this.loading) {
        return
      }
      this.loading = true
      this.timer = setTimeout(() => {
        // 动画关闭需要一定的时间
        setTimeout(() => {
          this.loading = false
        }, 400)
        this.tenantManagementSearch_Multiple()
        // 动画关闭需要一定的时间
        this.dialog = false
      }, 2000)
    },
    cancelForm() {
      this.loading = false
      this.dialog = false
      this.tenantAPPovalForm.province = ''
      this.tenantAPPovalForm.city = ''
      this.tenantAPPovalForm.area = ''
      clearTimeout(this.timer)
    },
    handleClosButtonClick(val) {
      this.dialogVisibleMenu = true
      this.addDialogVisible = true
      this.houseId = val.index.houseId
      this.queryMenuByRoleId()
    },
    //查询所有菜单树
    getMenuTree() {
      axios.post(`${this.$apiPath}/api/pm/menu/queryMenusAllTree/`).then(res => {
        this.menuTreeList = res.data
      })
    },
    //查询要回显的菜单树
    queryMenuByRoleId() {
      axios.get(`${this.$apiPath}/api/pm/menu/queryMenuByRoleId/` + this.houseId).then(res => {
        const tenantMenu = res.data
        const checkedMenuIds = []
        for (let i = 0; i < tenantMenu.length; i++) {
          if (tenantMenu != null && tenantMenu.length > 0) {
            checkedMenuIds.push(tenantMenu[i].menuId)
            if (tenantMenu[i].menuId.split('_').length - 1 == 0) {
              this.expandTreeNodesByDefault.push(tenantMenu[i].menuId)
            } else if (tenantMenu[i].menuId.split('_').length - 1 == 3) {
              this.expandTreeNodesByDefault.push(tenantMenu[i].menuId.slice(0, -2))
            }
          }
        }
        this.$refs.tree.setCheckedKeys(checkedMenuIds)
      })
    },
    //新增分配菜单
    handleSaveMenu() {
      const checkedMenu = this.$refs.tree.getCheckedNodes()
      const checkedMenuIds = []
      for (let i = 0; i < checkedMenu.length; i++) {
        checkedMenuIds.push(checkedMenu[i].menuId)
      }
      this.$confirm('是否要分配该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let params = new URLSearchParams()
        params.append('houseId', this.houseId)
        params.append('menuIds', checkedMenuIds)
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/pm/menu/updateRoleMenus`,
          data: params,
        }).then(res => {
          if (res.data.success == true) {
            this.$message({
              message: '操作成功',
              type: 'success',
            })
          } else {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
          this.dialogVisibleMenu = false
          this.$refs.tree.setCheckedKeys([])
        })
      })
    },
    handleClearMenu() {},
  },
}
</script>
<style>
.tableMain .ces-table-page {
  height: 100%;
}
.tableMain .ces-table {
  height: 100%;
}
.tableMain .el-table th {
  padding: 0;
  height: 35px;
}
.tableMain .el-table td {
  padding: 0;
  height: 35px;
}
</style>
<style scoped>
.boxbutton {
  width: 100%;
  height: 40px;
}
.tableMain {
  width: 100%;
  height: 82%;
}
</style>
