<template>
  <el-form-item label="省市区信息" :required="true" prop="address">
    <el-row :gutter="0">
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <el-select
            :disabled="disabled"
            ref="province"
            v-model="SellerList.province"
            placeholder="请选择省"
            class="address_select"
            @change="getCityData(SellerList.province, true)"
            size="mini"
          >
            <el-option v-for="item in province" :key="item.adcode" :label="item.name" :value="item.adcode"> </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <el-select
            :disabled="disabled"
            ref="city"
            v-model="SellerList.city"
            placeholder="请选择市"
            class="address_select"
            @change="getCityAreaData(SellerList.city, true)"
            size="mini"
          >
            <el-option v-for="item in city" :key="item.adcode" :label="item.name" :value="item.adcode"> </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <el-select
            :disabled="disabled"
            ref="region"
            v-model="SellerList.area"
            placeholder="请选择区"
            class="address_select"
            @change="postData()"
            size="mini"
          >
            <el-option v-for="item in region" :key="item.adcode" :label="item.name" :value="item.adcode"> </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
  </el-form-item>
</template>
<script>
import axios from 'axios'
export default {
  name: 'cityLinkageReg',
  data() {
    return {
      province: [],
      city: [],
      region: [],
    }
  },
  props: ['SellerList', 'disabled', 'prop'], //从父组件接受的数据
  methods: {
    // 获取省
    getProvinceData() {
      const data = new URLSearchParams()
      data.append('adcodeParent', 100000)
      data.append('level', 1)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/pm/region/getRegions`,
        data: data,
      }).then(res => {
        this.province = res.data
      })
    },
    // 点击地区获取市
    getCityData(id, is) {
      if (is) {
        //判断是否点击上一级
        this.SellerList.area = ''
        this.SellerList.city = ''
      }
      const data = new URLSearchParams()
      data.append('adcodeParent', id)
      data.append('level', 2)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/pm/region/getRegions`,
        data: data,
      }).then(res => {
        this.city = res.data
      })
      this.postData()
    },
    // 点击城市获取区
    getCityAreaData(id, is) {
      if (is) {
        //判断是否点击上一级
        this.SellerList.area = ''
      }
      const data = new URLSearchParams()
      data.append('adcodeParent', id)
      data.append('level', 3)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/pm/region/getRegions`,
        data: data,
      }).then(res => {
        this.region = res.data
        this.postData()
      })
    },
    // 向父组件传值
    postData() {
      if (this.$refs.province) {
        this.SellerList.provinceName = this.$refs.province.selected.label
      }
      if (this.$refs.city) {
        this.SellerList.cityName = this.$refs.city.selected.label
      }
      if (this.$refs.region) {
        this.SellerList.regionName = this.$refs.region.selected.label
      }

      // let province = this.province
      // let city = this.city
      // let region = this.region
      setTimeout(() => {
        this.$emit('SellerList', this.SellerList)
      }, 10)
    },
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.getProvinceData()
    })
  },
  watch: {
    //监听父组件传值的变化
    SellerList: {
      handler: function (val, oldval) {
        this.getCityData(val.province, false)
        this.getCityAreaData(val.city, false)
      },
      immediate: true, //关键
      deep: true,
    },
  },
}
</script>
